<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Name: "Nome",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Graduation: "Graduação",
    },
    es: {
      Name: "Nombre",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      table: {
        heade: ["Name", "E-mail", "Cellphone"],
        body: null,
      },

      statData: null,
    };
  },
  mounted() {
    api
      .get("network/clients")
      .then((response) => {
        if (response.data.status == "success") {
          this.table.body = response.data.list;
          this.statData = [
            {
              icon: "bx bx-user",
              title: "Total",
              value: response.data.total,
            },
          ];
        }
      })
      .catch((error) => {
        if (error) {
          this.table.body = []
        }
      })
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Rede</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Clientes</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="(stat, index) of statData" :key="index" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!table.body" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>

    <div v-else-if="table.body && table.body.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td v-for="(data, row) in td" :key="row">
                  {{ data }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>